import { isAdmin } from '../../../core/auth/authProvider';
import { CURRENT_DATE, GRID_MD, GRID_SM, GRID_XS, MAX_CURRENT_DATE, MAX_DATE, MAX_WIDTH, MIN_CURRENT_DATE, MIN_DATE, PHONE_MASK } from '../../../utils/constants';
import { Wrapper, TitleWrapper, Title, Fieldset, Text } from '../../../components/Main/Main.style';
import { onKeyDownHandle } from '../../../utils/helper';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import CheckBox from 'devextreme-react/check-box';
import TextArea from 'devextreme-react/text-area';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RangeRule,
} from 'devextreme-react/validator';
import { dateFormatFromApi } from '../../../utils/formats';

export const ReferralForm = ({
	getEntityReferralForm,

	onChangeFunc,

	onSaveReferralForm,
	onClickBack,
}) => {
	const sectionName = "referralForm";

	return (
		<Wrapper id={sectionName} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityReferralForm('loading')}
				position={{ of: `#${sectionName}` }} />
			{
				!getEntityReferralForm('loading') &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<TitleWrapper>
							{isAdmin() && <Button hint='Go back to forms' icon="back" onClick={onClickBack} />}
							<Tooltip title="Referral to Behavioral Health">
								<Title>Referral to Behavioral Health</Title>
							</Tooltip>
						</TitleWrapper>
					</Grid>
					<Grid item xs={GRID_XS} style={{ textAlign: 'right' }}>
						<Text>Anchorage 907-276-2700</Text>
						<Text>Atka 907-839-2232</Text>
						<Text>Nikolski 907-576-2204</Text>
						<Text>St. George 907-859-2254</Text>
						<Text>Unalaska 907-581-2751</Text>
						<Text><b>Hours of Operation:</b> Mon - Fri, 8:00AM - 4:30PM</Text>
					</Grid>
					<Grid item xs={GRID_XS} style={{ textAlign: 'center' }}>
						<Text><b>If individual is in crisis call 911, Public Safety, Village Public Safety Officer, and/or Careline 1-877-266-4357</b></Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							Behavioral Health, part of the Community Health Services at the Aleutian Pribilof Islands Association, Inc.
							(APIA), provides psychological and substance use assessments and treatment services in Anchorage, Atka,
							Nikolski, St. George and Unalaska. We offer services to Alaskan Native and non-native individuals.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Referral to BH was discussed with client AND client agreed to be contacted">
										<>
											<CheckBox
												text="Referral to BH was discussed with client AND client agreed to be contacted"
												value={getEntityReferralForm('agree')}
												onValueChanged={() => onChangeFunc('Agree')} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Text>Note: BH staff cannot contact a person without knowledge of referral and agreement to contact.</Text>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={8}>
						<Tooltip title="Person Being Referred">
							<>
								<TextBox
									label="Person Being Referred"
									value={getEntityReferralForm('person')}
									onValueChanged={(e) => onChangeFunc('Person', e)}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-person' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Date of Birth">
							<>
								<DateBox
									label="Date of Birth"
									type="date"
									value={dateFormatFromApi(getEntityReferralForm('dateOfBirth'))}
									onValueChanged={(e) => onChangeFunc('DateOfBirth', e)}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RangeRule
											message="Date cannot be younger than the current date and earlier than 1900"
											min={MIN_DATE}
											max={CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={8}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={getEntityReferralForm('address')}
									onValueChanged={(e) => onChangeFunc('Address', e, true)}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Phone Number">
							<>
								<TextBox
									label="Phone Number"
									value={getEntityReferralForm('phone')}
									onValueChanged={(e) => onChangeFunc('Phone', e)}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={8}>
						<Tooltip title="Referral Made by">
							<>
								<TextBox
									label="Referral Made by"
									value={getEntityReferralForm('referral')}
									onValueChanged={(e) => onChangeFunc('Referral', e)}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-referral' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Contact Number">
							<>
								<TextBox
									label="Contact Number"
									value={getEntityReferralForm('contact')}
									onValueChanged={(e) => onChangeFunc('Contact', e)}
									labelMode="floating"
									stylingMode="outlined"
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-contact' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Reason for Referral">
							<>
								<TextArea
									label="Reason for Referral"
									value={getEntityReferralForm('reason')}
									onValueChanged={(e) => onChangeFunc('Reason', e)}
									maxLength={140}
									labelMode="floating"
									stylingMode="outlined" />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Service Requested:">
							<>
								<TextArea
									label="Service Requested:"
									value={getEntityReferralForm('request')}
									onValueChanged={(e) => onChangeFunc('Request', e)}
									maxLength={140}
									labelMode="floating"
									stylingMode="outlined" />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<hr />
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><b>Disposition:</b></Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="APPT">
							<>
								<CheckBox
									text="APPT"
									value={getEntityReferralForm('appt')}
									onValueChanged={() => onChangeFunc('Appt')} />
							</>
						</Tooltip>
					</Grid>
					{getEntityReferralForm('appt') &&
						<Grid item xs={GRID_XS} title="Date/time, location, provider">
							<Tooltip title="Date/time, location, provider">
								<>
									<TextBox
										label="Date/time, location, provider"
										value={getEntityReferralForm('apptComment')}
										onValueChanged={(e) => onChangeFunc('ApptComment', e)}
										maxLength={30}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-apptComment' }} />
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS}>
						<Tooltip title="Declined">
							<>
								<CheckBox
									text="Declined"
									value={getEntityReferralForm('declined')}
									onValueChanged={() => onChangeFunc('Declined')} />
							</>
						</Tooltip>
					</Grid>
					{getEntityReferralForm('declined') &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="Reason">
								<>
									<TextBox
										label="Reason"
										value={getEntityReferralForm('declinedComment')}
										onValueChanged={(e) => onChangeFunc('DeclinedComment', e)}
										maxLength={30}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-declinedComment' }} />
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS}>
						<Tooltip title="No Answer / No Response to Attempts to Contact">
							<>
								<CheckBox
									text="No Answer / No Response to Attempts to Contact"
									value={getEntityReferralForm('noAnswer')}
									onValueChanged={() => onChangeFunc('NoAnswer')} />
							</>
						</Tooltip>
					</Grid>
					{!getEntityReferralForm('noAnswer') &&
						<>
							<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
								<Tooltip title="Date of 1st contact attempt">
									<>
										<DateBox
											label="Date of 1st contact attempt"
											type="date"
											value={dateFormatFromApi(getEntityReferralForm('firstAttempt'))}
											onValueChanged={(e) => onChangeFunc('FirstAttempt', e)}
											useMaskBehavior={true}
											displayFormat="MM/dd/yyyy"
											invalidDateMessage="The date must have the following format: MM/dd/yyyy"
											labelMode="floating"
											stylingMode="outlined"
										>
											<Validator validationGroup={sectionName}>
												<RangeRule
													message="Date cannot be earlier than 1900 and later than 2100"
													min={MIN_DATE}
													max={MAX_DATE} />
											</Validator>
										</DateBox>
									</>
								</Tooltip>
							</Grid>
							<Grid item xs={GRID_XS} sm={GRID_SM} md={8}>
								<Tooltip title="by">
									<>
										<TextBox
											label="by"
											value={getEntityReferralForm('firstAttemptBy')}
											onValueChanged={(e) => onChangeFunc('FirstAttemptBy', e)}
											maxLength={30}
											labelMode="floating"
											stylingMode="outlined"
											inputAttr={{ autocomplete: 'new-firstAttemptBy' }} />
									</>
								</Tooltip>
							</Grid>
							<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
								<Tooltip title="Date of 2nd contact attempt">
									<>
										<DateBox
											label="Date of 2nd contact attempt"
											type="date"
											value={dateFormatFromApi(getEntityReferralForm('secondAttempt'))}
											onValueChanged={(e) => onChangeFunc('SecondAttempt', e)}
											useMaskBehavior={true}
											displayFormat="MM/dd/yyyy"
											invalidDateMessage="The date must have the following format: MM/dd/yyyy"
											labelMode="floating"
											stylingMode="outlined"
										>
											<Validator validationGroup={sectionName}>
												<RangeRule
													message="Date cannot be earlier than 1900 and later than 2100"
													min={MIN_DATE}
													max={MAX_DATE} />
											</Validator>
										</DateBox>
									</>
								</Tooltip>
							</Grid>
							<Grid item xs={GRID_XS} sm={GRID_SM} md={8}>
								<Tooltip title="by">
									<>
										<TextBox
											label="by"
											value={getEntityReferralForm('secondAttemptBy')}
											onValueChanged={(e) => onChangeFunc('SecondAttemptBy', e)}
											labelMode="floating"
											stylingMode="outlined"
											inputAttr={{ autocomplete: 'new-secondAttemptBy' }} />
									</>
								</Tooltip>
							</Grid>
							<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
								<Tooltip title="Date of 3rd contact attempt">
									<>
										<DateBox
											label="Date of 3rd contact attempt"
											type="date"
											value={dateFormatFromApi(getEntityReferralForm('thirdAttempt'))}
											onValueChanged={(e) => onChangeFunc('ThirdAttempt', e)}
											useMaskBehavior={true}
											displayFormat="MM/dd/yyyy"
											invalidDateMessage="The date must have the following format: MM/dd/yyyy"
											labelMode="floating"
											stylingMode="outlined"
										>
											<Validator validationGroup={sectionName}>
												<RangeRule
													message="Date cannot be earlier than 1900 and later than 2100"
													min={MIN_DATE}
													max={MAX_DATE} />
											</Validator>
										</DateBox>
									</>
								</Tooltip>
							</Grid>
							<Grid item xs={GRID_XS} sm={GRID_SM} md={8}>
								<Tooltip title="by">
									<>
										<TextBox
											label="by"
											value={getEntityReferralForm('thirdAttemptBy')}
											onValueChanged={(e) => onChangeFunc('ThirdAttemptBy', e)}
											labelMode="floating"
											stylingMode="outlined"
											inputAttr={{ autocomplete: 'new-thirdAttemptBy' }} />
									</>
								</Tooltip>
							</Grid>
							<Grid item xs={GRID_XS}>
								<Tooltip title="Closed Referral due to no response">
									<>
										<CheckBox
											text="Closed Referral due to no response"
											value={getEntityReferralForm('closedReferral')}
											onValueChanged={() => onChangeFunc('ClosedReferral')} />
									</>
								</Tooltip>
							</Grid>
						</>
					}
					<Grid item xs={GRID_XS} sm={GRID_SM} md={8}>
						<Tooltip title="Referral followed up by">
							<>
								<TextBox
									label="Referral followed up by"
									value={getEntityReferralForm('referralFollowedUpBy')}
									onValueChanged={(e) => onChangeFunc('ReferralFollowedUpBy', e)}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-referralFollowedUpBy' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Date:">
							<>
								<DateBox
									label="Date:"
									type="date"
									value={dateFormatFromApi(getEntityReferralForm('referralFollowedUpDate'))}
									onValueChanged={(e) => onChangeFunc('ReferralFollowedUpDate', e)}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RangeRule
											message="Date cannot be earlier or later than that year"
											min={MIN_CURRENT_DATE}
											max={MAX_CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="contained"
							validationGroup={sectionName}
							onClick={onSaveReferralForm} />
					</Grid>
				</Grid>
			}
		</Wrapper >
	)
}