import { isAdmin } from '../../../core/auth/authProvider';
import { Wrapper, TitleWrapper, Title, Text, Fieldset } from '../../../components/Main/Main.style';
import { GRID_MD, GRID_SM, GRID_XS, MAX_DATE, MAX_WIDTH, MIN_DATE, PHONE_MASK } from '../../../utils/constants';
import { dateFormatFromApi } from '../../../utils/formats';
import { onKeyDownHandle } from '../../../utils/helper';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import CheckBox from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	EmailRule,
	RangeRule,
} from 'devextreme-react/validator';

export const GrievanceFeedbackForm = ({
	getEntityGrievanceFeedbackForm,

	onChangeFunc,

	onSaveGrievanceFeedbackForm,
	onClickBack,
}) => {
	const sectionName = "grievanceFeedbackForm";

	return (
		<Wrapper id={sectionName} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityGrievanceFeedbackForm('loading')}
				position={{ of: `#${sectionName}` }} />
			{
				!getEntityGrievanceFeedbackForm('loading') &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<TitleWrapper>
							{isAdmin() && <Button hint='Go back to forms' icon="back" onClick={onClickBack} />}
							<Tooltip title="Grievance & Feedback Form">
								<Title>Grievance & Feedback Form</Title>
							</Tooltip>
						</TitleWrapper>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>Information is required to better serve you. You may return this form to any employee.</Text>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Name">
							<>
								<TextBox
									label="Name"
									value={getEntityGrievanceFeedbackForm('name')}
									onValueChanged={(e) => onChangeFunc('Name', e)}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-name' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Phone">
							<>
								<TextBox
									label="Phone"
									value={getEntityGrievanceFeedbackForm('phone')}
									onValueChanged={(e) => onChangeFunc('Phone', e)}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Email">
							<>
								<TextBox
									label="Email"
									value={getEntityGrievanceFeedbackForm('email')}
									onValueChanged={(e) => onChangeFunc('Email', e, true)}
									maxLength={64}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-email' }}>
									<Validator validationGroup={sectionName}>
										<EmailRule
											message="Email is invalid"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={getEntityGrievanceFeedbackForm('address')}
									onValueChanged={(e) => onChangeFunc('Address', e, true)}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><strong>How would you like to be contacted?</strong></Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Phone">
										<>
											<CheckBox
												text="Phone"
												value={getEntityGrievanceFeedbackForm('typeСonnectionPhone')}
												onValueChanged={() => onChangeFunc('TypeСonnectionPhone')} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Email">
										<>
											<CheckBox
												text="Email"
												value={getEntityGrievanceFeedbackForm('typeСonnectionEmail')}
												onValueChanged={() => onChangeFunc('TypeConnectionEmail')} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Mail">
										<>
											<CheckBox
												text="Mail"
												value={getEntityGrievanceFeedbackForm('typeСonnectionMail')}
												onValueChanged={() => onChangeFunc('TypeConnectionMail')} />
										</>
									</Tooltip>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><strong>Best time to contact you</strong></Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Morning">
										<>
											<CheckBox
												text="Morning"
												value={getEntityGrievanceFeedbackForm('bestConnectionMorning')}
												onValueChanged={() => onChangeFunc('BestConnectionMorning')} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Afternoon">
										<>
											<CheckBox
												text="Afternoon"
												value={getEntityGrievanceFeedbackForm('bestConnectionAfternoon')}
												onValueChanged={() => onChangeFunc('BestConnectionAfternoon')} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Evening">
										<>
											<CheckBox
												text="Evening"
												value={getEntityGrievanceFeedbackForm('bestConnectionEvening')}
												onValueChanged={() => onChangeFunc('BestConnectionEvening')} />
										</>
									</Tooltip>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><strong>Status</strong></Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Patient/Client">
										<>
											<CheckBox
												text="Patient/Client"
												value={getEntityGrievanceFeedbackForm('statusPatientClient')}
												onValueChanged={() => onChangeFunc('StatusPatientClient')} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Visitor">
										<>
											<CheckBox
												text="Visitor"
												value={getEntityGrievanceFeedbackForm('statusVisitor')}
												onValueChanged={() => onChangeFunc('StatusVisitor')} />
										</>
									</Tooltip>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><strong>Comment Type</strong></Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Great job">
										<>
											<CheckBox
												text="Great job"
												value={getEntityGrievanceFeedbackForm('commentTypeGreatJob')}
												onValueChanged={() => onChangeFunc('CommentTypeGreatJob')} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Inquiry">
										<>
											<CheckBox
												text="Inquiry"
												value={getEntityGrievanceFeedbackForm('commentTypeInquiry')}
												onValueChanged={() => onChangeFunc('CommentTypeInquiry')} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Suggestion">
										<>
											<CheckBox
												text="Suggestion"
												value={getEntityGrievanceFeedbackForm('commentTypeSuggestion')}
												onValueChanged={() => onChangeFunc('CommentTypeSuggestion')} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Complaint/Grievance">
										<>
											<CheckBox
												text="Complaint/Grievance"
												value={getEntityGrievanceFeedbackForm('commentTypeComplaintGrievance')}
												onValueChanged={() => onChangeFunc('CommentTypeComplaintGrievance')} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Sliding Scale">
										<>
											<CheckBox
												text="Sliding Scale"
												value={getEntityGrievanceFeedbackForm('commentTypeSlidingScale')}
												onValueChanged={() => onChangeFunc('CommentTypeSlidingScale')} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Other">
										<>
											<CheckBox
												text="Other"
												value={getEntityGrievanceFeedbackForm('commentTypeOther')}
												onValueChanged={() => onChangeFunc('CommentTypeOther')} />
										</>
									</Tooltip>
								</Grid>
								{getEntityGrievanceFeedbackForm('commentTypeOther') &&
									<Grid item xs={GRID_XS}>
										<Tooltip title="Comment">
											<>
												<TextBox
													label="Comment"
													value={getEntityGrievanceFeedbackForm('commentTypeOtherText')}
													onValueChanged={(e) => onChangeFunc('CommentTypeOtherText', e)}
													maxLength={30}
													labelMode="floating"
													stylingMode="outlined"
													inputAttr={{ autocomplete: 'new-commentTypeOtherText' }} />
											</>
										</Tooltip>
									</Grid>
								}
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_SM}>
						<Tooltip title="Date of Event">
							<>
								<DateBox
									label="Date of Event"
									type="date"
									value={dateFormatFromApi(getEntityGrievanceFeedbackForm('date'))}
									onValueChanged={(e) => onChangeFunc('Date', e)}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_SM}>
						<Tooltip title="Clinic/Department">
							<>
								<TextBox
									label="Clinic/Department"
									value={getEntityGrievanceFeedbackForm('clinicDepartment')}
									onValueChanged={(e) => onChangeFunc('ClinicDepartment', e)}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-clinicDepartment' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Please describe your experience">
							<>
								<TextBox
									label="Please describe your experience"
									value={getEntityGrievanceFeedbackForm('experience')}
									onValueChanged={(e) => onChangeFunc('Experience', e)}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-experience' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="How would you like this experience resolved?">
							<>
								<TextBox
									label="How would you like this experience resolved?"
									value={getEntityGrievanceFeedbackForm('allowExperience')}
									onValueChanged={(e) => onChangeFunc('AllowExperience', e)}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-allowExperience' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							Thank you for using this card to communicate with us regarding our services.  It is designed for use by our clients/patients/visitors to share their compliments, suggestions and grievances regarding services.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							The information provided will be kept strictly confidential and used only to improve our services.  All comments will be reviewed in a timely manner.  Your input is sincerely valued and appreciated.  Thank you!
						</Text>
					</Grid>
					<Grid item xs={GRID_XS} style={{ textAlign: 'center' }}>
						<Text><strong>ATTN: Compliance Administrator</strong></Text>
						<Text><strong>Aleutian Pribilof Islands Association, Inc.</strong></Text>
						<Text><strong>1131 E. International Airport Road</strong></Text>
						<Text><strong>Anchorage, AK 99518</strong></Text>
						<Text><strong>Phone: 1-800-478-2742, 907-276-2700 (main)</strong></Text>
						<Text><strong>Fax: 907-222-4279 (Health Dept. Fax)</strong></Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							Our clients/patients/visitors also have the option to contact the following agencies concerning a grievance:
						</Text>
					</Grid>
					<Grid item xs={GRID_XS} style={{ textAlign: 'center' }}>
						<Text><strong>State of Alaska</strong></Text>
						<Text><strong>Dept. of Health and Social Services</strong></Text>
						<Text><strong>Div. of Health Care Services</strong></Text>
						<Text><strong>Certification/Licensing</strong></Text>
						<Text><strong>4501 Business Park Blvd., STE24</strong></Text>
						<Text><strong>Anchorage, AK 99503</strong></Text>
						<Text><strong>Phone: 907-334-2483   Fax: 907-334-2682</strong></Text>
					</Grid>
					<Grid item xs={GRID_XS} style={{ textAlign: 'center' }}>
						<Text><strong>Office of Quality Monitoring</strong></Text>
						<Text><strong>The Joint Commission</strong></Text>
						<Text><strong>One Renaissance Boulevard</strong></Text>
						<Text><strong>Oakbrook Terrace, Illinois 60181</strong></Text>
						<Text><strong>Email: complaint@jointcommission.org</strong></Text>
						<Text><strong>Phone: 800-994-6610</strong></Text>
						<Text><strong>Fax: 630-792-5636</strong></Text>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="contained"
							validationGroup={sectionName}
							onClick={onSaveGrievanceFeedbackForm} />
					</Grid>
				</Grid >
			}
		</Wrapper >
	);
};