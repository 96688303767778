import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { setLoading, setName, setAddress, setPhone, setEmail, toggleTypeСonnectionPhone, toggleTypeConnectionEmail, toggleTypeConnectionMail, toggleBestConnectionMorning, toggleBestConnectionAfternoon, toggleBestConnectionEvening, toggleStatusPatientClient, toggleStatusVisitor, toggleCommentTypeGreatJob, toggleCommentTypeInquiry, toggleCommentTypeSuggestion, toggleCommentTypeComplaintGrievance, toggleCommentTypeSlidingScale, toggleCommentTypeOther, setCommentTypeOtherText, setDate, setClinicDepartment, setExperience, setAllowExperience } from '../../../store/forms/grievanceFeedbackForm/slice';
import { getGrievanceFeedback, saveGrievanceFeedback, getGrievanceFeedbackByID, saveGrievanceFeedbackByID } from '../../../store/forms/grievanceFeedbackForm/middlewares';

import { GrievanceFeedbackForm } from './GrievanceFeedbackForm';
import { validateGroup } from '../../../utils/helper';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { showToast } from '../../Main/MainContainer';
import { isAdmin } from '../../../core/auth/authProvider';
import { USER_PATH } from '../../../utils/constants';

export const GrievanceFeedbackFormContainer = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	const stateGrievanceFeedbackForm     = useSelector(state => state.grievanceFeedbackForm);
	const getEntityGrievanceFeedbackForm = (name) => stateGrievanceFeedbackForm[name];

	useEffect(() => {
		dispatch(setLoading(true));
		dispatch(id ? getGrievanceFeedbackByID(id) : getGrievanceFeedback());
	}, [dispatch, id]);

	const FN_TO_CALL = {
		Name: setName,
		Address: setAddress,
		Phone: setPhone,
		Email: setEmail,
		TypeСonnectionPhone: toggleTypeСonnectionPhone,
		TypeConnectionEmail: toggleTypeConnectionEmail,
		TypeConnectionMail: toggleTypeConnectionMail,
		BestConnectionMorning: toggleBestConnectionMorning,
		BestConnectionAfternoon: toggleBestConnectionAfternoon,
		BestConnectionEvening: toggleBestConnectionEvening,
		StatusPatientClient: toggleStatusPatientClient,
		StatusVisitor: toggleStatusVisitor,
		CommentTypeGreatJob: toggleCommentTypeGreatJob,
		CommentTypeInquiry: toggleCommentTypeInquiry,
		CommentTypeSuggestion: toggleCommentTypeSuggestion,
		CommentTypeComplaintGrievance: toggleCommentTypeComplaintGrievance,
		CommentTypeSlidingScale: toggleCommentTypeSlidingScale,
		CommentTypeOther: toggleCommentTypeOther,
		CommentTypeOtherText: setCommentTypeOtherText,
		Date: setDate,
		ClinicDepartment: setClinicDepartment,
		Experience: setExperience,
		AllowExperience: setAllowExperience,
	};

	const onChangeFunc = (name, event = null, trim = false) => {
		if(event) dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
		else dispatch(FN_TO_CALL[name]());
	};

	const onSaveGrievanceFeedbackForm = (event) => {
		const isValid = validateGroup(event);

		if (isValid) {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});

			dispatch(id ? saveGrievanceFeedbackByID(id) : saveGrievanceFeedback()).then(() => {
				!isAdmin() && navigate('/main/forms/all');
			}).catch(() => {
				showToast('error', 'Error to save Grievance & Feedback Form');
			});
		}
	};

	const onClickBack = () => {
		const path = location.state?.path ? location.state.path : window.localStorage.getItem(USER_PATH);
		navigate(`/main/users/${path}/profile/${id}`, { state: { indexTab: 2 } });
	};

	return (
		<GrievanceFeedbackForm
			getEntityGrievanceFeedbackForm={getEntityGrievanceFeedbackForm}

			onChangeFunc={onChangeFunc}

			onSaveGrievanceFeedbackForm={onSaveGrievanceFeedbackForm}
			onClickBack={onClickBack}
		/>
	);
}