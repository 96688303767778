import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { selectSignPicture, selectLoading, selectDeclineEmail, selectDateEmail, selectNameEmail, selectEmailEmail, selectCheckboxEmail, selectDeclineTelehealth, selectDateTelehealth, selectNameTelehealth, selectEmailTelehealth, selectCheckboxTelehealth } from '../../../store/forms/eCommunicationConsent/selectors';
import { setSignPicture, setLoading, toggleDeclineTelehealth, setDateTelehealth, setEmailTelehealth, setNameTelehealth, toggleCheckboxTelehealth, toggleDeclineEmail, setEmailEmail, setNameEmail, setDateEmail, toggleCheckboxEmail } from '../../../store/forms/eCommunicationConsent/slice';
import { getECommunicationConsent, saveECommunicationConsent, getECommunicationConsentByID, saveECommunicationConsentByID } from '../../../store/forms/eCommunicationConsent/middlewares';
import { completeECommunicationConsent } from '../../../store/forms/newPatientRegistrationPacket/slice';
import { setNewActiveStep } from '../../../store/forms/newPatientRegistrationPacket/middlewares';

import { ECommunicationConsent } from './ECommunicationConsent';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { validateGroup } from '../../../utils/helper';
import { USER_PATH } from '../../../utils/constants';

export const ECommunicationConsentContainer = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();

	const [signature, setSignature] = useState({});
	const signPicture = useSelector(selectSignPicture);

	const [error, setError] = useState(false);
	const loading = useSelector(selectLoading);

	const declineTelehealth = useSelector(selectDeclineTelehealth);
	const emailTelehealth = useSelector(selectEmailTelehealth);
	const nameTelehealth = useSelector(selectNameTelehealth);
	const dateTelehealth = useSelector(selectDateTelehealth);
	const checkboxTelehealth = useSelector(selectCheckboxTelehealth);

	const declineEmail = useSelector(selectDeclineEmail);
	const checkboxEmail = useSelector(selectCheckboxEmail);
	const emailEmail = useSelector(selectEmailEmail);
	const nameEmail = useSelector(selectNameEmail);
	const dateEmail = useSelector(selectDateEmail);

	useEffect(() => {
		dispatch(setLoading(true));
		id ? dispatch(getECommunicationConsentByID(id)) : dispatch(getECommunicationConsent());
	}, [dispatch, id]);

	useEffect(() => {
		if (!!signature?.fromDataURL && !!signature?.clear && signPicture) {
			signature.clear();
			signature.fromDataURL(signPicture);
		};
	}, [signPicture, signature]);

	const onToggleDeclineTelehealth = () => {
		dispatch(toggleDeclineTelehealth());
	}

	const onChangeEmailTelehealth = (event) => {
		dispatch(setEmailTelehealth(event.value.trim()));
	}

	const onChangeNameTelehealth = (event) => {
		dispatch(setNameTelehealth(event.value.trim()));
	}

	const onChangeDateTelehealth = (event) => {
		dispatch(setDateTelehealth(event.value));
	}

	const onToggleCheckboxTelehealth = () => {
		dispatch(toggleCheckboxTelehealth());
	}

	const onToggleDeclineEmail = () => {
		dispatch(toggleDeclineEmail());
	}

	const onChangeEmailEmail = (event) => {
		dispatch(setEmailEmail(event.value.trim()));
	}

	const onChangeNameEmail = (event) => {
		dispatch(setNameEmail(event.value.trim()));
	}

	const onChangeDateEmail = (event) => {
		dispatch(setDateEmail(event.value));
	}

	const onToggleCheckboxEmail = () => {
		dispatch(toggleCheckboxEmail());
	}

	const onInitSignature = (event) => {
		setSignature(event);
	}

	const onChangeSignature = () => {
		setError(false);
	}

	const onClearSignature = () => {
		if (!!signature.clear) {
			signature.clear();
		}
	}

	const onSaveECommunicationConsent = (event) => {
		const picture = signature.toDataURL('image/png');
		const isValid = validateGroup(event);

		if (signature.isEmpty()) {
			setError(true);
		}

		if (isValid && !signature.isEmpty()) {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});

			dispatch(setSignPicture(picture));
			dispatch(id ? saveECommunicationConsentByID(id) : saveECommunicationConsent());
			dispatch(completeECommunicationConsent(true));
			dispatch(setNewActiveStep());
		}
	}

	const onClickBack = () => {
		const path = location.state?.path ? location.state.path : window.localStorage.getItem(USER_PATH);
		navigate(`/main/users/${path}/profile/${id}`, { state: { indexTab: 2 } });
	};

	return (
		<ECommunicationConsent
			loading={loading}
			error={error}

			declineTelehealth={declineTelehealth}
			emailTelehealth={emailTelehealth}
			nameTelehealth={nameTelehealth}
			dateTelehealth={dateTelehealth}
			checkboxTelehealth={checkboxTelehealth}

			declineEmail={declineEmail}
			checkboxEmail={checkboxEmail}
			emailEmail={emailEmail}
			nameEmail={nameEmail}
			dateEmail={dateEmail}
			signPicture={signPicture}

			onToggleDeclineTelehealth={onToggleDeclineTelehealth}
			onChangeEmailTelehealth={onChangeEmailTelehealth}
			onChangeNameTelehealth={onChangeNameTelehealth}
			onChangeDateTelehealth={onChangeDateTelehealth}
			onToggleCheckboxTelehealth={onToggleCheckboxTelehealth}

			onToggleDeclineEmail={onToggleDeclineEmail}
			onChangeEmailEmail={onChangeEmailEmail}
			onChangeNameEmail={onChangeNameEmail}
			onChangeDateEmail={onChangeDateEmail}
			onToggleCheckboxEmail={onToggleCheckboxEmail}

			onInitSignature={onInitSignature}
			onChangeSignature={onChangeSignature}
			onClearSignature={onClearSignature}

			onSaveECommunicationConsent={onSaveECommunicationConsent}
			onClickBack={onClickBack}
		/>
	)
}