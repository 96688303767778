import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { selectSignPicture, selectLoading, selectPrintedName, selectDOB, selectAddress, selectCity, selectState, selectZip, selectPhone, selectLegalGuardian, selectAtkaHealthClinic, selectAnchorageBehavioralHealth, selectNikolskiHealthCenter, selectSaintGeorgeHealthCenter, selectOonalaskaPrimaryCare, selectOonalaskaBehavioralHealth, selectReleaseInformationTo, selectObtainInformationFrom, selectVerbally, selectInWritingElectronically, selectAgency, selectAgencyName, selectAgencyAddress, selectAgencyCity, selectAgencyState, selectAgencyZip, selectAgencyPhone, selectAssessmentRecommendations, selectAdmissionSummary, selectResultsOfUrinalysis, selectAttendanceCompliance, selectTreatmentPlan, selectPsychiatricEvaluations, selectTreatmentStatus, selectDischargeDateAndSummary, selectBhMedicationNeeds, selectRegardingOther, selectRegardingOtherText, selectReferralTreatmentPlacement, selectProvideReferralInformation, selectVerifyParticipationInTreatment, selectCoordinationOfServices, selectPurposesOther, selectPurposesOtherText, selectExpiredDate, selectSignedDate, selectFirstName, selectMiddleName, selectLastName, selectDecline } from "../../../store/forms/consentForRelease/selectors";
import { setSignPicture, setLoading, setPrintedName, setAddress, setAgency, setAgencyAddress, setAgencyCity, setAgencyName, setAgencyPhone, setAgencyState, setAgencyZip, setCity, setDOB, setLegalGuardian, setPhone, togglePurposesOther, toggleRegardingOther, setState, setZip, toggleAdmissionSummary, toggleAnchorageBehavioralHealth, toggleAssessmentRecommendations, toggleAtkaHealthClinic, toggleAttendanceCompliance, toggleBhMedicationNeeds, toggleCoordinationOfServices, toggleDischargeDateAndSummary, toggleInWritingElectronically, toggleNikolskiHealthCenter, toggleObtainInformationFrom, toggleOonalaskaBehavioralHealth, toggleOonalaskaPrimaryCare, toggleProvideReferralInformation, togglePsychiatricEvaluations, toggleReferralTreatmentPlacement, toggleReleaseInformationTo, toggleResultsOfUrinalysis, toggleSaintGeorgeHealthCenter, toggleTreatmentPlan, toggleTreatmentStatus, toggleVerbally, toggleVerifyParticipationInTreatment, setRegardingOtherText, setPurposesOtherText, setExpiredDate, setSignedDate, setFirstName, setMiddleName, setLastName, toggleDecline } from "../../../store/forms/consentForRelease/slice";
import { completeConsentForRelease } from "../../../store/forms/newPatientRegistrationPacket/slice";
import { setNewActiveStep } from "../../../store/forms/newPatientRegistrationPacket/middlewares";

import { getConsentForRelease, saveConsentForRelease, getConsentForReleaseByID, saveConsentForReleaseByID } from "../../../store/forms/consentForRelease/middlewares";
import { selectStates } from "../../../store/lookups/state/selectors";
import { getState } from "../../../store/lookups/state/middlewares";

import { ConsentForRelease } from "./ConsentForRelease";
import { useDispatch, useSelector } from "react-redux";
import { validateGroup } from '../../../utils/helper';
import { useEffect, useState } from 'react';
import { USER_PATH } from '../../../utils/constants';

export const ConsentForReleaseContainer = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();

	const [signature, setSignature] = useState({});
	const signPicture = useSelector(selectSignPicture);

	const [error, setError] = useState(false);
	const [isValidAgencyPhone, setisValidAgencyPhone] = useState(true);
	const [isValidAgencyZip, setisValidAgencyZip] = useState(true);
	const loading = useSelector(selectLoading);

	const states = useSelector(selectStates);
	const decline = useSelector(selectDecline);
	const firstName = useSelector(selectFirstName);
	const middleName = useSelector(selectMiddleName);
	const lastName = useSelector(selectLastName);
	const dob = useSelector(selectDOB);
	const address = useSelector(selectAddress);
	const city = useSelector(selectCity);
	const state = useSelector(selectState);
	const zip = useSelector(selectZip);
	const phone = useSelector(selectPhone);
	const legalGuardian = useSelector(selectLegalGuardian);

	const atkaHealthClinic = useSelector(selectAtkaHealthClinic);
	const anchorageBehavioralHealth = useSelector(selectAnchorageBehavioralHealth);
	const nikolskiHealthCenter = useSelector(selectNikolskiHealthCenter);
	const saintGeorgeHealthCenter = useSelector(selectSaintGeorgeHealthCenter);
	const oonalaskaPrimaryCare = useSelector(selectOonalaskaPrimaryCare);
	const oonalaskaBehavioralHealth = useSelector(selectOonalaskaBehavioralHealth);

	const releaseInformationTo = useSelector(selectReleaseInformationTo);
	const obtainInformationFrom = useSelector(selectObtainInformationFrom);
	const verbally = useSelector(selectVerbally);
	const inWritingElectronically = useSelector(selectInWritingElectronically);

	const agency = useSelector(selectAgency);
	const agencyName = useSelector(selectAgencyName);
	const agencyAddress = useSelector(selectAgencyAddress);
	const agencyCity = useSelector(selectAgencyCity);
	const agencyState = useSelector(selectAgencyState);
	const agencyZip = useSelector(selectAgencyZip);
	const agencyPhone = useSelector(selectAgencyPhone);

	const assessmentRecommendations = useSelector(selectAssessmentRecommendations);
	const admissionSummary = useSelector(selectAdmissionSummary);
	const resultsOfUrinalysis = useSelector(selectResultsOfUrinalysis);
	const attendanceCompliance = useSelector(selectAttendanceCompliance);
	const treatmentPlan = useSelector(selectTreatmentPlan);
	const psychiatricEvaluations = useSelector(selectPsychiatricEvaluations);
	const treatmentStatus = useSelector(selectTreatmentStatus);
	const dischargeDateAndSummary = useSelector(selectDischargeDateAndSummary);
	const bhMedicationNeeds = useSelector(selectBhMedicationNeeds);
	const regardingOther = useSelector(selectRegardingOther);
	const regardingOtherText = useSelector(selectRegardingOtherText);

	const referralTreatmentPlacement = useSelector(selectReferralTreatmentPlacement);
	const provideReferralInformation = useSelector(selectProvideReferralInformation);
	const verifyParticipationInTreatment = useSelector(selectVerifyParticipationInTreatment);
	const coordinationOfServices = useSelector(selectCoordinationOfServices);
	const purposesOther = useSelector(selectPurposesOther);
	const purposesOtherText = useSelector(selectPurposesOtherText);

	const printedName = useSelector(selectPrintedName);
	const signedDate = useSelector(selectSignedDate);
	const expiredDate = useSelector(selectExpiredDate);

	useEffect(() => {
		dispatch(setLoading(true));
		dispatch(getState());
		id ? dispatch(getConsentForReleaseByID(id)) : dispatch(getConsentForRelease());
	}, [dispatch, id]);

	useEffect(() => {
		if (!!signature?.fromDataURL && !!signature?.clear && signPicture) {
			signature.clear();
			signature.fromDataURL(signPicture);
		};
	}, [signPicture, signature]);

	const onChangeFirstName = (event) => {
		dispatch(setFirstName(event.value.trim()));
	}

	const onToggleDecline = (event) => {
		dispatch(toggleDecline());
		if (event.value) {
			dispatch(id ? saveConsentForReleaseByID(id) : saveConsentForRelease());
			dispatch(completeConsentForRelease(true));
			dispatch(setNewActiveStep());
		}
	}

	const onChangeMiddleName = (event) => {
		dispatch(setMiddleName(event.value.trim()));
	}

	const onChangeLastName = (event) => {
		dispatch(setLastName(event.value.trim()));
	}

	const onChangeDOB = (event) => {
		dispatch(setDOB(event.value));
	}

	const onChangeAddress = (event) => {
		dispatch(setAddress(event.value.trim()));
	}

	const onChangeCity = (event) => {
		dispatch(setCity(event.value.trim()));
	}

	const onChangeState = (event) => {
		dispatch(setState(event.value));
	}

	const onChangeZip = (event) => {
		dispatch(setZip(event.value));
	}

	const onChangePhone = (event) => {
		dispatch(setPhone(event.value));
	}

	const onChangeLegalGuardian = (event) => {
		dispatch(setLegalGuardian(event.value.trim()));
	}

	const onToggleAtkaHealthClinic = () => {
		dispatch(toggleAtkaHealthClinic());
	}

	const onToggleAnchorageBehavioralHealth = () => {
		dispatch(toggleAnchorageBehavioralHealth());
	}

	const onToggleNikolskiHealthCenter = () => {
		dispatch(toggleNikolskiHealthCenter());
	}

	const onToggleSaintGeorgeHealthCenter = () => {
		dispatch(toggleSaintGeorgeHealthCenter());
	}

	const onToggleOonalaskaPrimaryCare = () => {
		dispatch(toggleOonalaskaPrimaryCare());
	}

	const onToggleOonalaskaBehavioralHealth = () => {
		dispatch(toggleOonalaskaBehavioralHealth());
	}

	const onToggleReleaseInformationTo = () => {
		dispatch(toggleReleaseInformationTo());
	}

	const onToggleObtainInformationFrom = () => {
		dispatch(toggleObtainInformationFrom());
	}

	const onToggleVerbally = () => {
		dispatch(toggleVerbally());
	}

	const onToggleInWritingElectronically = () => {
		dispatch(toggleInWritingElectronically());
	}

	const onChangeAgency = (event) => {
		dispatch(setAgency(event.value.trim()));
	}

	const onChangeAgencyName = (event) => {
		dispatch(setAgencyName(event.value.trim()));
	}

	const onChangeAgencyAddress = (event) => {
		dispatch(setAgencyAddress(event.value.trim()));
	}

	const onChangeAgencyCity = (event) => {
		dispatch(setAgencyCity(event.value.trim()));
	}

	const onChangeAgencyState = (event) => {
		dispatch(setAgencyState(event.value));
	}

	const onChangeAgencyZip = (event) => {
		dispatch(setAgencyZip(event.value));
		setisValidAgencyZip(event.value.length === 5);
	}

	const onChangeAgencyPhone = (event) => {
		dispatch(setAgencyPhone(event.value));
		setisValidAgencyPhone(agencyPhone.length === 10);
	}

	const onToggleAssessmentRecommendations = () => {
		dispatch(toggleAssessmentRecommendations());
	}

	const onToggleAdmissionSummary = () => {
		dispatch(toggleAdmissionSummary());
	}

	const onToggleResultsOfUrinalysis = () => {
		dispatch(toggleResultsOfUrinalysis());
	}

	const onToggleAttendanceCompliance = () => {
		dispatch(toggleAttendanceCompliance());
	}

	const onToggleTreatmentPlan = () => {
		dispatch(toggleTreatmentPlan());
	}

	const onTogglePsychiatricEvaluations = () => {
		dispatch(togglePsychiatricEvaluations());
	}

	const onToggleTreatmentStatus = () => {
		dispatch(toggleTreatmentStatus());
	}

	const onToggleDischargeDateAndSummary = () => {
		dispatch(toggleDischargeDateAndSummary());
	}

	const onToggleBhMedicationNeeds = () => {
		dispatch(toggleBhMedicationNeeds());
	}

	const onToggleRegardingOther = () => {
		dispatch(toggleRegardingOther());
	}

	const onChangeRegardingOtherText = (event) => {
		dispatch(setRegardingOtherText(event.value));
	}

	const onToggleReferralTreatmentPlacement = () => {
		dispatch(toggleReferralTreatmentPlacement());
	}

	const onToggleProvideReferralInformation = () => {
		dispatch(toggleProvideReferralInformation());
	}

	const onToggleVerifyParticipationInTreatment = () => {
		dispatch(toggleVerifyParticipationInTreatment());
	}

	const onToggleCoordinationOfServices = () => {
		dispatch(toggleCoordinationOfServices());
	}

	const onTogglePurposesOther = () => {
		dispatch(togglePurposesOther());
	}

	const onChangePurposesOtherText = (event) => {
		dispatch(setPurposesOtherText(event.value));
	}

	const onChangePrintedName = (event) => {
		dispatch(setPrintedName(event.value.trim()));
	}

	const onChangeSignedDate = (event) => {
		dispatch(setSignedDate(event.value));
	}

	const onChangeExpiredDate = (event) => {
		dispatch(setExpiredDate(event.value));
	}

	const onInitSignature = (event) => {
		setSignature(event);
	}

	const onChangeSignature = () => {
		setError(false);
	}

	const onClearSignature = () => {
		if (!!signature.clear) {
			signature.clear();
		}
	}

	const onSaveConsentForRelease = (event) => {
		const picture = signature.toDataURL('image/png');
		const isValid = validateGroup(event);

		setisValidAgencyPhone(agencyPhone.length === 10);
		setisValidAgencyZip(agencyZip.length === 5);

		if (signature.isEmpty()) {
			setError(true);
		}

		if (isValid && agencyZip.length === 5 && agencyPhone.length === 10 && !signature.isEmpty()) {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});

			dispatch(setSignPicture(picture));
			dispatch(id ? saveConsentForReleaseByID(id) : saveConsentForRelease());
			dispatch(completeConsentForRelease(true));
			dispatch(setNewActiveStep());
		}
	}

	const onClickBack = () => {
		const path = location.state?.path ? location.state.path : window.localStorage.getItem(USER_PATH);
		navigate(`/main/users/${path}/profile/${id}`, { state: { indexTab: 2 } });
	};

	return (
		<ConsentForRelease
			loading={loading}
			error={error}

			isValidAgencyPhone={isValidAgencyPhone}
			isValidAgencyZip={isValidAgencyZip}

			states={states}

			decline={decline}
			firstName={firstName}
			middleName={middleName}
			lastName={lastName}
			dob={dob}
			address={address}
			city={city}
			state={state}
			zip={zip}
			phone={phone}
			legalGuardian={legalGuardian}

			atkaHealthClinic={atkaHealthClinic}
			anchorageBehavioralHealth={anchorageBehavioralHealth}
			nikolskiHealthCenter={nikolskiHealthCenter}
			saintGeorgeHealthCenter={saintGeorgeHealthCenter}
			oonalaskaPrimaryCare={oonalaskaPrimaryCare}
			oonalaskaBehavioralHealth={oonalaskaBehavioralHealth}

			releaseInformationTo={releaseInformationTo}
			obtainInformationFrom={obtainInformationFrom}
			verbally={verbally}
			inWritingElectronically={inWritingElectronically}

			agency={agency}
			agencyName={agencyName}
			agencyAddress={agencyAddress}
			agencyCity={agencyCity}
			agencyState={agencyState}
			agencyZip={agencyZip}
			agencyPhone={agencyPhone}

			assessmentRecommendations={assessmentRecommendations}
			admissionSummary={admissionSummary}
			resultsOfUrinalysis={resultsOfUrinalysis}
			attendanceCompliance={attendanceCompliance}
			treatmentPlan={treatmentPlan}
			psychiatricEvaluations={psychiatricEvaluations}
			treatmentStatus={treatmentStatus}
			dischargeDateAndSummary={dischargeDateAndSummary}
			bhMedicationNeeds={bhMedicationNeeds}
			regardingOther={regardingOther}
			regardingOtherText={regardingOtherText}

			referralTreatmentPlacement={referralTreatmentPlacement}
			provideReferralInformation={provideReferralInformation}
			verifyParticipationInTreatment={verifyParticipationInTreatment}
			coordinationOfServices={coordinationOfServices}
			purposesOther={purposesOther}
			purposesOtherText={purposesOtherText}

			printedName={printedName}
			signedDate={signedDate}
			expiredDate={expiredDate}
			signPicture={signPicture}

			onToggleDecline={onToggleDecline}
			onChangeFirstName={onChangeFirstName}
			onChangeMiddleName={onChangeMiddleName}
			onChangeLastName={onChangeLastName}
			onChangeDOB={onChangeDOB}
			onChangeAddress={onChangeAddress}
			onChangeCity={onChangeCity}
			onChangeState={onChangeState}
			onChangeZip={onChangeZip}
			onChangePhone={onChangePhone}
			onChangeLegalGuardian={onChangeLegalGuardian}

			onToggleAtkaHealthClinic={onToggleAtkaHealthClinic}
			onToggleAnchorageBehavioralHealth={onToggleAnchorageBehavioralHealth}
			onToggleNikolskiHealthCenter={onToggleNikolskiHealthCenter}
			onToggleSaintGeorgeHealthCenter={onToggleSaintGeorgeHealthCenter}
			onToggleOonalaskaPrimaryCare={onToggleOonalaskaPrimaryCare}
			onToggleOonalaskaBehavioralHealth={onToggleOonalaskaBehavioralHealth}

			onToggleReleaseInformationTo={onToggleReleaseInformationTo}
			onToggleObtainInformationFrom={onToggleObtainInformationFrom}
			onToggleVerbally={onToggleVerbally}
			onToggleInWritingElectronically={onToggleInWritingElectronically}

			onChangeAgency={onChangeAgency}
			onChangeAgencyName={onChangeAgencyName}
			onChangeAgencyAddress={onChangeAgencyAddress}
			onChangeAgencyCity={onChangeAgencyCity}
			onChangeAgencyState={onChangeAgencyState}
			onChangeAgencyZip={onChangeAgencyZip}
			onChangeAgencyPhone={onChangeAgencyPhone}

			onToggleAssessmentRecommendations={onToggleAssessmentRecommendations}
			onToggleAdmissionSummary={onToggleAdmissionSummary}
			onToggleResultsOfUrinalysis={onToggleResultsOfUrinalysis}
			onToggleAttendanceCompliance={onToggleAttendanceCompliance}
			onToggleTreatmentPlan={onToggleTreatmentPlan}
			onTogglePsychiatricEvaluations={onTogglePsychiatricEvaluations}
			onToggleTreatmentStatus={onToggleTreatmentStatus}
			onToggleDischargeDateAndSummary={onToggleDischargeDateAndSummary}
			onToggleBhMedicationNeeds={onToggleBhMedicationNeeds}
			onToggleRegardingOther={onToggleRegardingOther}
			onChangeRegardingOtherText={onChangeRegardingOtherText}

			onToggleReferralTreatmentPlacement={onToggleReferralTreatmentPlacement}
			onToggleProvideReferralInformation={onToggleProvideReferralInformation}
			onToggleVerifyParticipationInTreatment={onToggleVerifyParticipationInTreatment}
			onToggleCoordinationOfServices={onToggleCoordinationOfServices}
			onTogglePurposesOther={onTogglePurposesOther}
			onChangePurposesOtherText={onChangePurposesOtherText}

			onChangePrintedName={onChangePrintedName}
			onChangeSignedDate={onChangeSignedDate}
			onChangeExpiredDate={onChangeExpiredDate}

			onInitSignature={onInitSignature}
			onChangeSignature={onChangeSignature}
			onClearSignature={onClearSignature}

			onSaveConsentForRelease={onSaveConsentForRelease}
			onClickBack={onClickBack}
		/>
	)
}